<template>
  <div class="toggler-wrapper">
    <label class="switcher">
      <input
          ref="checkbox"
          type="checkbox"
          @click="toggleCheckbox"
      >
      <div class="slider round" />
    </label>
    <div class="toggler-label">
      <slot name="label" />
    </div>
  </div>

</template>

<script>
  export default {
    name: "Toggle",
    props: {
      value: {
        type: Boolean,
        default: false,
      }
    },
    mounted() {
      this.$refs.checkbox.checked = this.value
    },
    methods: {
      toggleCheckbox() {
        this.$emit('input', !this.value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .toggler-wrapper {
    display: flex;
    align-items: center;

    font-family: var(--new-front-font-family);

    gap: 10px;

    .switcher {
      position: relative;
      display: inline-block;

      width: 51px;
      height: 31px;
    }

    .switcher input {
      display: none;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #D8D3E3;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 29px;
      width: 29px;
      left: 1px;
      bottom: 1px;
      background-color: #fff;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #6750A4;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #6750A4;
    }

    input:checked + .slider:before {
      transform: translateX(20px);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    .toggler-label {
      font-size: $h4;
      font-weight: 500;
    }
  }
</style>
