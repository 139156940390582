<template>
  <div class="base-input__wrapper">
    <input
      v-model="valueModel"
      :class="{ 'error': !!error, 'suffixed': $slots['action-btn-slot'] }"
      :placeholder="placeholder"
      :type="type"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); "
      :maxlength="maxLength"
      v-bind="$attrs"
      :disabled="disabled"
      step="0.01"
    />
    <div
      v-if="$slots['action-btn-slot']"
      class="action"
      @click="$emit('action-btn-click')"
    >
      <slot name="action-btn-slot" />
    </div>
<!--    <div class="subItem-block">-->
<!--      <div-->
<!--        v-if="error"-->
<!--        class="error"-->
<!--      >-->
<!--        {{ error }}-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: [Number, String],
      default: 999999999
    },
    minLength: {
      type: Number,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    error: {
      type: [String, Boolean],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: [Number, String],
      default: undefined
    },
    min: {
      type: [Number, String],
      default: undefined
    },
    allowNumberSeparation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueModel: {
      get({ value }) {
        return value || ''
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.base-input {
  &__wrapper {
    font-family: var(--new-front-font-family);

    width: 100%;
    position: relative;
    .label-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 5px;

      font-size: $h6;
      line-height: $h6;

      .label {
        color: var(--new-front-input-label-font-color);
      }
      .error {
        color: var(--new-front-input-font-color-error);
      }
    }
    .subItem-block {
      font-size: $h6;
      line-height: $h6;

      .error {
        display: none;
        margin-top: 5px;
        color: var(--new-front-input-font-color-error);
      }
    }

    input {
      width: 100%;

      background-color: transparent;
      border: 1px solid var(--new-front-input-border);
      border-radius: 10px;

      color: var(--new-front-primary-font-color);

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      height: 48px;
      padding: 0 40px 0 20px;
      font-weight: 500;
      font-size: $h4;
      line-height: $h4;

      &:focus, textarea:focus, select:focus {
        outline: none !important;
      }
      &.error {
        border: 1px solid var(--new-front-input-border-error);
      }
      &:disabled {
        opacity: .6;
      }

      &.suffixed {
        padding-right: 110px;
      }
    }

    .action {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      height: 90%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    //Remove Arrows/Spinners
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }

    ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder  { /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-style: normal;
      font-weight: 500;

      font-size: $h4;
      line-height: $h4;

      //color: var(--new-front-secondary-font-color);
      color: red;
      opacity: 1; /* Firefox */
    }

    @include below_phone() {
      .label-block {
        font-weight: 400;
        font-size: $h8;
        line-height: $h8;

        .error {
          display: none;
        }
      }
      ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder  {
        font-size: $h6;
        line-height: $h6;
      }

      input {
        font-size: $h6;
        line-height: $h6;
      }

      .subItem-block {
        font-weight: 400;
        font-size: $h8;
        line-height: $h8;

        .error {
          display: block;
        }
      }
    }
  }
}
</style>
