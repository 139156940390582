import {
    STEP_START_PAGE,
    STEP_PAY_SELECT,
    STEP_CONSTRUCTOR,
    STEP_WIDGET,
    MANAGE_CREATE_TYPE
} from '@/public/pages/widgets/constants'

// payinCryptoType
// {
//     INVOICE
//     PERSONAL
// }

// type payinCryptoCurrenciesTo {
//     currency: String
//     currencyId: String
//     network: String
//     networkId: String
// }

// type IFrameWidgetCreatePayloadCurrenciesItem {
//     currency: String
//     currencyId: String
//     network: String
//     networkId: String
// }

export const state = {
    currentStep: STEP_START_PAGE,
    allSteps: [STEP_START_PAGE, STEP_PAY_SELECT, STEP_CONSTRUCTOR, STEP_WIDGET],
    isLoading: false,
    widgets: [],
    widgetsCount: 0,
    host: '',
    manageType: MANAGE_CREATE_TYPE,
    widget: {
        alias: '',
        theme: 'dark',
        siteURL: '',
        showStartPage: true,
        payinCrypto: false,
        payoutCrypto: false,
        payinCryptoType: '',
        payinCryptoInvoiceFree: false,
        payinCryptoInvoiceInsurancePercent: '',
        payinCryptoInvoiceSlippagePercent: '',
        payinCryptoInvoiceTolerancePercent: '',
        payinCryptoInvoiceCommissionOnPayer: false,
        payinCryptoInvoicePayerEmailAllow: false,
        payinCryptoInvoicePayerEmailRequired: false,
        payinCryptoInvoiceCurrencies: [],
        payinCryptoCurrenciesTo: [],
        payoutCryptoCurrenciesTo: [],
        payoutCryptoCurrenciesFrom: [],
        payinCryptoWebhookURL: '',
        payoutCryptoWebhookURL: '',
        payoutCryptoApprovalWebhookURL: '',
        apiKeyId: '',
    },
    demo: {
        payinCryptoInvoiceAmount: '',
        payinCryptoInvoiceCurrencies: [],
        payinCryptoInvoiceId: '',
        payinCryptoInvoiceDesc: '',
        payinCryptoInvoicePayerEmail: '',
        payinCryptoInvoicePayerEmailRequired: '',
        payinCryptoInvoicePayerEmailAllow: '',
        payinCryptoInvoiceLifetime: '',
        payinCryptoWebhook: '',
        payoutCryptoCurrenciesTo: [],
        payoutCryptoWebhook: '',
        payoutCryptoWebhookApproval: '',
        payoutCryptoReceiverEmail: '',
        payoutCryptoCurrencyFrom: '',
    }
};
