<script>
  import WidgetsManageSettings from "@/public/components/elements/widgets-manage/settings/widgetsManageSettings.vue";
  import WidgetsManagePreview from "@/public/components/elements/widgets-manage/preview/widgetsManagePreview.vue";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import WidgetsDemoLinkBlock from "@/public/components/elements/widgets-manage/settings/WidgetsDemoLinkBlock.vue";
  import {MANAGE_CREATE_TYPE, MANAGE_DEMO_TYPE, MANAGE_EDIT_TYPE, STEP_WIDGET} from "@/public/pages/widgets/constants";

  export default {
    name: "widgetsManage",
    components: {WidgetsDemoLinkBlock, WidgetsManagePreview, WidgetsManageSettings},
    beforeMount() {
      this.fetchApiKeys();
    },
    STEP_WIDGET,
    methods: {
      ...mapActions('user', [
        'fetchApiKeys',
        'fetchApiRulesMap'
      ]),
      ...mapMutations('widgetsManage', ['clearManageState']),
      goToWidgets () {
        this.clearManageState()
        this.$router.push({name: 'widgets'});
      }
    },
    computed: {
      ...mapState('widgetsManage', ['currentStep', 'manageType']),
      ...mapGetters('organizations', ['userRoleRules']),
    },
    mounted () {
      const isCreateAllowed = this.userRoleRules['iframe_widgets'].entities.create
      const isEditAllowed = this.userRoleRules['iframe_widgets'].entities.update
      const isViewAllowed = this.userRoleRules['iframe_widgets'].entities.view

      if (!isCreateAllowed && this.manageType === MANAGE_CREATE_TYPE) {
        this.$Message.error(this.$t('rules.not_allowed'));
        this.$router.push({ name: 'widgets' });
        return
      }
      if (!isEditAllowed && this.manageType === MANAGE_EDIT_TYPE) {
        this.$Message.error(this.$t('rules.not_allowed'));
        this.$router.push({ name: 'widgets' });
        return
      }
      if (!isViewAllowed && this.manageType === MANAGE_DEMO_TYPE) {
        this.$Message.error(this.$t('rules.not_allowed'));
        this.$router.push({ name: 'widgets' });
      }
    },
    watch: {
      '$route.name': {
        deep: true,
        handler (to, from) {
          this.clearManageState()
        }
      }
    }
  }
</script>

<template>
 <div class="widgets-manage-page">
      <div @click="goToWidgets" class="backBtn">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0781 3.58313C10.8191 3.45457 10.5072 3.47641 10.2701 3.6397L4.8257 7.3897C4.62131 7.53048 4.5 7.75779 4.5 8C4.5 8.24221 4.62131 8.46952 4.8257 8.6103L10.2701 12.3603C10.5072 12.5236 10.8191 12.5454 11.0781 12.4169C11.3372 12.2883 11.5 12.0309 11.5 11.75V4.25C11.5 3.96906 11.3372 3.71168 11.0781 3.58313Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
      </svg>
        {{ $t('widgets.return') }}
      </div>
      <div class="wrapper">
        <!--    Настройки    -->
          <WidgetsManageSettings />
        <!--    Превью    -->
          <WidgetsManagePreview />
      </div>
 </div>
</template>

<style scoped lang="scss">
.demo-wrapper {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 20;
  width: 100%;
  @media (max-width: 850px) {
    display: none;
  }
}
.widgets-manage-page {
  padding: 0 30px 30px;
  font-family: $Gilroy;
  @media (max-width: 850px) {
    padding: 0 10px 10px;
  }
  .wrapper {
    position: relative;
    width: 100%;
    border-radius: 30px;
    border: 1px solid var(--border-border-neutral-variant, #26272F);
    background: var(--bg-background-secondary, #202020);
    display: grid;
    grid-template-columns: clamp(436px, 37%, 536px) 1fr;
    height: 800px;
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
    }
    //overflow: hidden;
  }
  .backBtn {
    display: flex;
    align-items: center;
    gap: 7px;
    color: var(--text-text-primary-accent, #6750A4);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
</style>
