<script>
  import vClickOutside from "v-click-outside";
  import {mapState} from "vuex";
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';

  export default{
    name: "WidgetsTooltip",
    directives: {
      clickOutside: vClickOutside.directive
    },
    components: {
      'popper': Popper
    },
    props: {
      closeOutside: {
        type: Boolean,
        default: true
      },
      trigger: {
        type: String,
        default: 'hover'
      },
      text: {
        type: String,
      },
      title: {
        type: String,
      }
    },
    data () {
      return {
        isModalOpen: false
      }
    },
    computed: {
      ...mapState(['themeStatus']),
      modifiers () {
        return [
          {
            name: 'offset',
            options: {
              offset: [10, 20],
            },
          },
        ]
      }
    },
    methods: {
      toggleFilter (event) {
        this.isModalOpen = !this.isModalOpen
      },
    }
  }
</script>

<template>
<div class="tooltip">
  <transition name="slide-fade">
    <popper
        :trigger="trigger"
        :options="{
              placement: 'top-start',
              modifiers: modifiers
            }"
    >
      <div class="tooltip__trigger" slot="reference" ref="actionsTrigger">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="var(--text-color-text-secondary)"/>
          <path d="M8.9307 6.58789L6.63969 6.875L6.55766 7.25586L7.00883 7.33789C7.3018 7.4082 7.36039 7.51367 7.29594 7.80664L6.55766 11.2754C6.3643 12.1719 6.66313 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3418 8.83109 11.9961L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6328 7.92875 11.293L8.9307 6.58789Z" fill="var(--text-color-text-secondary)"/>
          <path d="M9 4.5C9 5.05228 8.55229 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5Z" fill="var(--text-color-text-secondary)"/>
        </svg>
      </div>

      <div class="tooltip__modal">
        <p class="tooltip__title">{{ title }}</p>
        <p class="tooltip__text">
          {{ text }}
        </p>
      </div>
      <!--          <div class="tooltip__arrow arrow" ref="arrow" id="arrow" data-popper-arrow></div>-->
    </popper>
  </transition>
</div>
</template>

<style scoped lang="scss">
.tooltip {
  position: relative;
  z-index: 4;
  font-family: $Gilroy;
  &__arrow {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    background: var(--new-front-nav-tooltip-bg);
    border: 1px solid var(--new-front-input-border);
    bottom: -10px;
    left: calc(50% - 10px);
    clip-path: polygon(0% 0%,100% 100%,0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 5px;

  }
  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    cursor: pointer;
    z-index: 1;
    @media (max-width: 850px) {
      width: 13px;
      height: 13px;
    }
  }
  &__modal {
    position: absolute;
    right: -5px;
    top: -5px !important;
    background: var(--bg-background-table);
    border: 1px solid var(--border-border-input-primary-default);
    min-width: 260px;
    width: fit-content;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    z-index: 5;
    white-space: normal;
    //overflow: hidden;
    padding: 15px;

  }
  &__text {
    color: var(--text-text-primary);
    font-weight: 500;
    text-align: left;
    font-size: 13px;
    font-family: $Gilroy;
    line-height: 110%; /* 14.3px */
    letter-spacing: -0.15px;

  }
  &__title {
    color: var(--text-text-primary);
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    font-family: $Gilroy;
    margin-bottom: 5px;
    width: 100%;
  }
}
//::v-deep {
//  .popper__arrow {
//    position: absolute;
//    display: block;
//    height: 20px;
//    width: 20px;
//    background: #F3F0F8;
//    border: 1px solid var(--new-front-input-border);
//    bottom: -10px;
//    //left: calc(50% - 10px);
//    clip-path: polygon(0% 0%,100% 100%,0% 100%);
//    transform: rotate(-45deg);
//    border-radius: 0 0 0 5px;
//  }
//}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
.arrow,
.arrow::before {
  position: absolute;
  width: 20px;
  height: 20px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
</style>
