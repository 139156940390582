<script>
  import WidgetsManageTopHeader from "@/public/components/elements/widgets-manage/widgetsManageTopHeader.vue";
  import WidgetsManageSteps from "@/public/components/elements/widgets-manage/widgetsManageSteps.vue";
  import WidgetsManageChooseForm from "@/public/components/elements/widgets-manage/settings/WidgetsManageChooseForm.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
  import {mapActions, mapMutations, mapState} from "vuex";
  import WidgetsManageConstructor
    from "@/public/components/elements/widgets-manage/settings/WidgetsManageConstructor.vue";
  import {
    PAYIN_CRYPTO_TYPE_INVOICE,
    PAYIN_CRYPTO_TYPE_PERSONAL,
    STEP_WIDGET,
    STEP_PAY_SELECT,
    STEP_START_PAGE,
    STEP_CONSTRUCTOR,
    MANAGE_CREATE_TYPE,
    MANAGE_EDIT_TYPE
  } from "@/public/pages/widgets/constants";
  import WidgetsDemoLinkBlock from "@/public/components/elements/widgets-manage/settings/WidgetsDemoLinkBlock.vue";
  import WidgetsManageDemoConstructor
    from "@/public/components/elements/widgets-manage/settings/WidgetsManageDemoConstructor.vue";
  import validUrl from "valid-url";



  export default {
    name: "widgetsManageSettings",
    components: {
      WidgetsManageDemoConstructor,
      WidgetsDemoLinkBlock,
      WidgetsManageConstructor,
      BaseButton, WidgetsManageChooseForm, WidgetsManageSteps, WidgetsManageTopHeader},
    STEP_WIDGET,
    computed: {
      ...mapState('widgetsManage', ['widget', 'currentStep', 'manageType']),
      showMethods () {
        return this.currentStep === STEP_START_PAGE || this.currentStep === STEP_PAY_SELECT
      },
      isButtonNextDisabled () {
        if (this.currentStep === STEP_START_PAGE) {
          return !validUrl.isHttpUri(this.widget.siteURL, true)|| !this.widget.alias
        }
        if (this.currentStep === STEP_PAY_SELECT) {
          // !this.widget.payoutCrypto &&
          return  !this.widget.payinCryptoType || !this.widget.alias
        }
        if (this.currentStep === STEP_CONSTRUCTOR) {
          if (this.widget.payinCryptoType === PAYIN_CRYPTO_TYPE_INVOICE) {
            return !this.widget.apiKeyId || !this.widget.payinCryptoWebhookURL || !this.widget.payinCryptoCurrenciesTo.length || (!this.widget.payinCryptoInvoiceCurrencies.length && !this.widget.payinCryptoInvoiceFree)
          }
          if (this.widget.payinCryptoType === PAYIN_CRYPTO_TYPE_PERSONAL) {
            return !this.widget.apiKeyId || !this.widget.payinCryptoCurrenciesTo || !this.widget.payinCryptoWebhookURL || !this.widget.payinCryptoCurrenciesTo.length
          }
        }
        return false
      },
      widgetStep () {
        return this.currentStep === STEP_WIDGET
      },
      buttonLabel () {
        return this.currentStep === STEP_CONSTRUCTOR ? this.$t('widgets.demo') : this.$t('widgets.next')
      }
    },
    methods: {
      ...mapActions('widgetsManage', ['createWidget', 'updateWidget']),
      ...mapMutations('widgetsManage', ['setNextStep', 'clearManageState']),
      goToWidgets () {
        this.$router.push({ name: 'widgets' })
        this.clearManageState()
      },
      async changeStep () {
        if (this.currentStep === STEP_CONSTRUCTOR && this.manageType === MANAGE_CREATE_TYPE) {
          await this.createWidget()
          return
        }
        if (this.currentStep === STEP_CONSTRUCTOR && this.manageType === MANAGE_EDIT_TYPE) {
          await this.updateWidget()
          return
        }
        this.setNextStep()
      }
    }
  }
</script>

<template>
<div class="settings">
<!-- TopHeader -->
  <div v-if="!widgetStep" class="companyName">
    <widgets-manage-top-header />
  </div>
<!-- Steps -->
  <WidgetsManageSteps v-if="!widgetStep" />
<!-- Settings -->
  <WidgetsManageChooseForm v-if="showMethods" />
  <WidgetsManageConstructor v-else-if="!showMethods && !widgetStep" />
  <WidgetsManageDemoConstructor v-if="widgetStep" />
<!-- Footer -->
  <div  class="footer">
    <base-button v-if="!widgetStep" :label="buttonLabel"  :disabled="isButtonNextDisabled" @click="changeStep"/>
    <base-button v-else :label="$t('widgets.end')" @click="goToWidgets"/>
  </div>
</div>
</template>

<style scoped lang="scss">
.settings {
  position: relative;
  background: var(--bg-background-secondary);
  max-height: 900px;
  overflow: auto;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  @media (max-width: 850px) {
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0;
    max-height: unset;
  }
  .companyName {
    padding: 20px 30px 30px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (max-width: 850px) {
      padding: 20px;
    }
  }
  .footer {
    padding: 0 30px 30px 30px;
    @media (max-width: 850px) {
      padding: 0 20px 20px 20px;
    }
  }
}
</style>
